import React, { useEffect, useState, useMemo } from 'react'
import Amplify from '@aws-amplify/core'
import { Theme, Tooltip, Typography, withStyles } from '@material-ui/core'
import { LocationProps } from '@reach/router'
import { graphql, navigate } from 'gatsby'
import { TFunction } from 'i18next'
import { withTranslation } from 'react-i18next'
import {
  generateTrackingLink,
  getBrands,
  getBrandsLogosURLSrc,
} from '../../utils/brandsHelper'
import { trendingBrandsLogos } from '../../utils/trendingBrandsLogos'
import { sendExtensionMessage } from '../../utils/extensionHelper'
import { isUserAuthenticated } from '../../utils/isUserAuthenticated'
import { useAppState } from '../appState'
import BrandItem from '../components/newBrandItem'
import BrandCardsList from '../components/newBrandsList'
import ExtensionWarningModal from '../components/extensionWarningModal'
import { FONTS, HASH_SIGN_IN, ROUTES } from '../constants'
import useNavigateToExtStore from '../hooks/useNavigateToExtStore'
import Layout from '../layouts/defaultLayout'
import { styles } from '../pagesStyles/homeStyles'
import { userRemovedExtension } from './../../utils/userRemovedExtension'
import awsConfig from './../aws-exports'
import BlockUserInterface from './../components/blockUserInterface'
import ContentContainer from './../components/contentContainer'
import CHROutlinedButton from './../components/outlinedButton'
import SEO from '../components/seo'
import CHRButton from '../components/button'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import insider from '../assets/images/insider.jpg'
import bird from '../assets/images/chirpyest-logo.svg'
import * as moment from 'moment'
import { setItemToLocalStorage } from '../../utils/setLocalStorage'

Amplify.configure(awsConfig)

const HtmlTooltip = withStyles((theme: Theme) => ({
  arrow: {
    color: theme.palette.primary.main,
  },
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
    display: 'flex',
    alignItems: 'center',
    padding: 10,
  },
}))(Tooltip)
interface HomePageProps {
  data: {
    site: {
      siteMetadata: {
        title: string
      }
    }
    file: {
      childImageSharp: {
        fluid: any
      }
    }
  }
  t: TFunction
  location: LocationProps
}

const quotations = [
  {
    type: 'insider',
    text: '“…[chirpyest] has a democratic angle over competitors…”',
    from: 'business insider, June 2022',
    link:
      'https://www.businessinsider.com/influencer-affiliate-marketing-chirpyest-colette-shelton-2022-5',
  },
  {
    type: 'customer',
    text:
      '“the chirpyest browser makes it easy to earn cash on every purchase…”',
    from: 'Anne, chirpyest member since 2022',
    link: '',
  },
]

const LPV1 = ({ data, t, location, ...otherProps }: HomePageProps) => {
  const [appState] = useAppState()
  const classes = styles()
  const [
    isExtWarningModalOpen,
    setExtWarningModalOpen,
    navigateToStore,
  ] = useNavigateToExtStore()

  const [landingBrands, setLandingBrands] = useState([])
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [param] = window.location.search.slice(1).split('&')
  const [extensionInstalled, setExtensionInstalled] = useState(true)
  let extensionTimeoutRef = useMemo(
    () =>
      setTimeout(() => {
        setExtensionInstalled(false)
      }, 1 * 1000),
    []
  )

  useEffect(() => {
    userRemovedExtension(param)
    isUserAuthenticated().then(result => {
      if (!!result) {
        const urlParams = new URLSearchParams(window.location.search)
        const signInFromExtension = urlParams.get('signInFromExtension')
        navigate(
          `${ROUTES.member}${
            signInFromExtension
              ? '?signInFromExtension=signInFromExtension'
              : ''
          }`
        )
      } else {
        setIsPageLoading(false)
      }
    })
    getBrands()
      .then(response => {
        let filteredBrands = response.data.data.filter(
          (item: any) => item.isTrending
        )
        const filteredTrendingBrands = getBrandsLogosURLSrc(
          filteredBrands,
          trendingBrandsLogos
        )
        const limitedBrands = filteredTrendingBrands.slice(0, 12)
        setLandingBrands(limitedBrands)
      })
      .catch(() => setLandingBrands([]))
    // getBlogsHelper()
    window.addEventListener('chirpExtMessage', onSendExtMessage)
    sendExtensionMessage('ping')
    return window.removeEventListener('chirpExtMessage', onSendExtMessage)
  }, [])

  const onSendExtMessage = data => {
    if (data.detail === 'pong') {
      setExtensionInstalled(true)
      window.localStorage.setItem('extensionDownloaded', 'true')
      clearTimeout(extensionTimeoutRef)
    } else {
      window.localStorage.setItem('extensionDownloaded', 'false')
    }
  }

  if (location?.pathname.replace('/lpv1', '').length > 0) {
    setItemToLocalStorage(
      'referralId',
      JSON.stringify({
        id: location?.pathname.replace('/lpv1', '').replace('/', ''),
        time: moment().format(),
      })
    )
  }

  if (isPageLoading) {
    return <BlockUserInterface />
  } else {
    return (
      <Layout openSignInModal={location.hash.includes(HASH_SIGN_IN)}>
        {/* <SEO
          title={t('seo.landingPage.title')}
          description={t('seo.landingPage.description')}
        /> */}

        <section className={classes.landingSections}>
          <Typography variant="h3" className={classes.headingLarge}>
            shop, share, earn cash back!
          </Typography>
          <Typography
            variant="subtitle1"
            component="p"
            className={classes.paragraphStyle}
          >
            A new way to earn up to 30% cash back from 1000’s of retailers.
          </Typography>
          <CHRButton
            onClick={() => navigate(ROUTES.join)}
            label="create free account"
            customStyle={classes.buttonStyles}
          />
        </section>

        <section className={classes.landingSections}>
          <Typography variant="h3" className={classes.headingMedium}>
            share products you love & make money
          </Typography>
          <Typography
            variant="subtitle1"
            component="p"
            className={classes.paragraphStyle}
          >
            earn cash back when friends or followers buy what you recommend!
          </Typography>
          <CHROutlinedButton
            label={'start sharing'}
            font={FONTS.Graphik.GraphikMedium}
            onClick={() => navigate(ROUTES.join)}
            customStyle={classes.buttonOutlineStyles}
          />
        </section>

        <section className={classes.landingSections}>
          <Typography variant="h3" className={classes.headingMedium}>
            shop & earn up to 30%
          </Typography>
          <Typography
            variant="subtitle1"
            component="p"
            className={classes.paragraphStyle}
          >
            get cash back when you shop from over 1000 affiliated retailers
            using the chirpyest browser extension
          </Typography>
          <CHROutlinedButton
            label={'learn more'}
            font={FONTS.Graphik.GraphikMedium}
            onClick={navigateToStore}
            customStyle={classes.buttonOutlineStyles}
          />
        </section>

        <section className={classes.landingSections}>
          <Typography variant="h3" className={classes.headingMedium}>
            create a shopping board + earn cash back
          </Typography>
          <Typography
            variant="subtitle1"
            component="p"
            className={classes.paragraphStyle}
          >
            earn money when friends or followers shop your personal store.
          </Typography>
          <CHROutlinedButton
            label={'browse member boards'}
            font={FONTS.Graphik.GraphikMedium}
            onClick={() => navigate(ROUTES.theChirpyest)}
            customStyle={classes.buttonOutlineStyles}
          />
        </section>

        <section className={classes.landingSections}>
          <Typography variant="h3" className={classes.headingMedium}>
            get paid
          </Typography>
          <Typography
            variant="subtitle1"
            component="p"
            className={classes.paragraphStyle}
          >
            we pay you cash back directly to your Paypal or Venmo account
          </Typography>
          <CHROutlinedButton
            label={'create free account'}
            font={FONTS.Graphik.GraphikMedium}
            onClick={() => navigate(ROUTES.join)}
            customStyle={classes.buttonOutlineStyles}
          />
        </section>

        {/*---------------------- section 6 - BRANDS LIST ----------------------*/}

        <div className={classes.flexOrder}>
          <div className={classes.brandsListStyle}>
            <div className={classes.mobileBackgroundStyle}>
              <section className={classes.brandsListSection}>
                <div className={classes.brandsListTitle}>
                  <Typography variant="h2" className={classes.brandsHeading}>
                    {t('home.thousandsOfBrands')}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    className={classes.subtitleBrandsSentence}
                  >
                    {t('home.homeFashionBeauty')}
                  </Typography>
                  <div className={classes.addChirpyestButtonStyle}>
                    <CHROutlinedButton
                      label={'all brands'}
                      font={FONTS.Graphik.GraphikMedium}
                      onClick={() => navigate(ROUTES.brands)}
                    />
                  </div>
                </div>
                <div className={classes.brandsListContainer}>
                  <BrandCardsList>
                    {landingBrands.map((brand: any, index: number) => {
                      return (
                        <BrandItem
                          brandLogo={brand.src}
                          name={brand.brandName}
                          cashBack={brand.commission}
                          link={generateTrackingLink(
                            brand,
                            {
                              userId: appState.userId,
                              userName: appState.userProfile?.userName,
                            },
                            '',
                            '',
                            Boolean(appState.userId)
                          )}
                          key={index}
                          isCardOnLanding
                        />
                      )
                    })}
                  </BrandCardsList>
                </div>
              </section>
            </div>
          </div>
        </div>

        {/*---------------------- section 7 - Quotations ----------------------*/}

        <section className={classes.landingSections}>
          <Carousel
            swipeable={true}
            showArrows={false}
            showThumbs={false}
            emulateTouch={true}
            showStatus={false}
          >
            {quotations.map((quote, index) => {
              return (
                <div key={index}>
                  {quote.type === 'insider' && (
                    <>
                      <img
                        src={insider}
                        alt="insider"
                        className={classes.quoteImage}
                      />
                      <HtmlTooltip
                        interactive
                        title={
                          <a
                            href={quote.link}
                            target="_blank"
                            rel="noreferrer"
                            className={classes.whiteLink}
                          >
                            business insider, june 2022
                          </a>
                        }
                      >
                        <a
                          href={quote.link}
                          target="_blank"
                          rel="noreferrer"
                          className={classes.link}
                        >
                          <Typography variant="h3" className={classes.quote}>
                            {quote.text}
                          </Typography>
                        </a>
                      </HtmlTooltip>
                    </>
                  )}
                  {quote.type === 'customer' && (
                    <>
                      <img
                        src={bird}
                        alt="customer"
                        className={classes.quoteImage}
                      />
                      <Typography variant="h3" className={classes.quote}>
                        {quote.text}
                      </Typography>
                    </>
                  )}

                  <Typography
                    variant="subtitle1"
                    className={classes.subtitleBrandsSentence}
                  >
                    {quote.from}
                  </Typography>
                </div>
              )
            })}
          </Carousel>
        </section>

        {/*------------ section 8 - What are you waiting for ------------*/}
        <div className={classes.sectionContainer + ' ' + classes.waitingFor}>
          <ContentContainer>
            <section className={classes.centered}>
              <Typography
                variant="h1"
                component="h2"
                className={classes.headingMedium}
              >
                {t('home.whatAreYouWaiting')}
              </Typography>
              <CHROutlinedButton
                label={'get started'}
                font={FONTS.Graphik.GraphikMedium}
                onClick={() => navigate(ROUTES.join)}
                customStyle={classes.buttonStyles}
              />
            </section>
          </ContentContainer>
        </div>
        <ExtensionWarningModal
          isOpen={isExtWarningModalOpen}
          onClose={() => setExtWarningModalOpen(false)}
        />
      </Layout>
    )
  }
}

export default withTranslation()(LPV1)

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    file(relativePath: { eq: "chirpyest-mobile.png" }) {
      childImageSharp {
        fluid(maxWidth: 1024) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
