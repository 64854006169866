import React from 'react'
import {
  Dialog,
  Box,
  DialogTitle,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import closeMenuIcon from '../../assets/images/close.svg'
import { styles } from './styles'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import CHROutlinedButton from '../outlinedButton'

interface ExtensionWarningModalProps {
  onClose: any
  isOpen: boolean
  onCTAButtonClicked: any
  t: TFunction
}

const ExtensionWarningModal = ({
  onClose,
  isOpen,
  onCTAButtonClicked,
  t,
}: ExtensionWarningModalProps) => {
  const classes = styles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Dialog
      fullScreen={fullScreen}
      open={isOpen}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <section className={classes.dialogContainer}>
        <DialogTitle
          id="responsive-dialog-title"
          className={classes.dialogTitleSection}
        >
          <button onClick={onClose} className={classes.closeButton}>
            <img src={closeMenuIcon} alt={t('imageAlts.close')} />
          </button>
          <Typography variant="h2">browser not supported</Typography>
        </DialogTitle>
        <Typography variant="body1">
          extension is available for Safari 14 and higher, please upgrade to
          latest version of Safari or use Chrome
        </Typography>
        <Box mt={2} />
        <CHROutlinedButton
          label="download chrome extension"
          onClick={() => {
            window.open(process.env.GATSBY_CHROME_EXTENSION_URL, '_blank')
            if (onCTAButtonClicked) onCTAButtonClicked()
          }}
        />
      </section>
    </Dialog>
  )
}

export default withTranslation()(ExtensionWarningModal)
