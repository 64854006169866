import { makeStyles } from '@material-ui/core/styles'
import { BREAKPOINTS, COLORS, FONTS, WIDTH_DIMENSIONS } from './../constants'

export const styles = makeStyles(theme => ({
  howItWorksHeading: {
    marginTop: 10,
    fontFamily: 'Schnyder XL Light',
    fontSize: '3.75rem',
    fontWeight: 300,
    display: 'block',
    lineHeight: '86px',
    letterSpacing: '-0.01562em',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      // marginTop: 30,
    },
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      fontSize: '2.25rem',
      lineHeight: '40px',
    },
  },
  seoHeading: {
    fontSize: '16px !important',
    fontFamily: 'Graphik Light !important',
    letterSpacing: '0.00938em !important',
    lineHeight: '1.75 !important',
    marginBottom: '40px !important',
    marginTop: '10px !important',
    fontWeight: 400,
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      marginBottom: '60px !important',
    },
  },
  heroContainer: {
    maxWidth: 460,
    margin: 'auto',
    fontFamily: FONTS.Graphik.GraphikLight,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& p': {
      textAlign: 'center',
      '& span': {
        fontWeight: 'bold',
      },
    },
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      maxWidth: 960,
    },
  },
  rating: {
    color: theme.palette.common.black,
    opacity: 1,
  },
  chromeReviews: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'center',
  },
  customChromeReviews: {
    display: 'flex',
    alignItems: 'baseline',
    '& span': {
      fontWeight: 'bold',
    },
  },
  button: {
    margin: 'auto',
    fontFamily: FONTS.Graphik.GraphikMedium,
    fontSize: '0.75rem',
  },
  imagePlaceholder: {
    width: '60%',
    height: 521,
    [`@media (max-width: ${BREAKPOINTS.md})`]: {
      width: '100%',
    },
  },
  brandsListSection: {
    position: 'relative',
    [`@media (min-width: ${BREAKPOINTS.xl}px)`]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  brandsListContainer: {
    width: '100%',
    [`@media (min-width: ${BREAKPOINTS.xl}px)`]: {
      width: '65%',
    },
  },
  addChirpyestBtn: {
    position: 'absolute',
    bottom: -95,
    right: '5%',
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      position: 'static',
      display: 'block',
    },
  },
  brandsListTitle: {
    width: '95%',
    margin: '0 auto 30px 20px',
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      width: '70%',
      margin: '0 auto 30px 20px',
    },
    [`@media (min-width: ${BREAKPOINTS.xl}px)`]: {
      width: '30%',
      margin: '0 auto 30px 10px',
    },
  },
  howItWorksContainer: {
    paddingLeft: 10,
  },
  brandsText: {
    marginTop: 107,
    fontFamily: FONTS.Schnyder.SchnyderXLLight,
    fontSize: '2rem',
    paddingLeft: '5rem',
  },
  centered: {
    maxWidth: 960,
    marginLeft: 'auto',
    marginRight: 'auto',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      textAlign: 'center',
    },
  },
  lightMoodButton: {
    width: '50%',
  },
  reviewNumbers: {
    margin: 0,
    marginLeft: 10,
  },
  millionMember: {
    margin: 0,
    marginTop: 5,
  },
  buttonContainer: {
    width: '100%',
    maxWidth: 460,
    margin: '0px auto',
  },
  customButtonContainer: {
    maxWidth: 444,
    margin: 0,
  },
  chromeExtensionInfo: {
    display: 'none',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      display: 'block',
      marginTop: 35,
    },
  },
  customChromeExtensionInfo: {
    fontFamily: FONTS.Graphik.GraphikLight,
    marginTop: 35,
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      fontFamily: FONTS.Graphik.GraphikLight,
      marginTop: 35,
    },
  },
  desktopView: {
    display: 'none',
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      display: 'block',
    },
  },
  mobileView: {
    display: 'block',
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      display: 'none',
    },
  },
  content: {
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      maxWidth: 444,
      '& p': {
        marginBottom: '0 !important',
      },
    },
  },
  customWidth: {
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      maxWidth: 444,
    },
  },
  customWidth2: {
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      maxWidth: 428,
    },
  },
  customWidth3: {
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      maxWidth: 484,
    },
  },
  customWidth4: {
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      maxWidth: 370,
    },
  },
  customWidth5: {
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      maxWidth: 420,
    },
  },
  customWidth6: {
    [`@media (max-width: ${BREAKPOINTS.lg}px)`]: {
      maxWidth: 275,
    },
  },
  customWidth7: {
    [`@media (max-width: ${BREAKPOINTS.lg}px)`]: {
      maxWidth: 300,
    },
  },
  boldText: {
    fontWeight: 'bolder',
    color: theme.palette.common.black,
  },
  underLinedText: {
    textDecoration: 'underline',
  },
  clickable: {
    cursor: 'pointer',
    color: theme.palette.common.black,
  },
  chirpyestLogo: {
    width: 31,
    height: 32,
    marginLeft: 5,
  },
  browserLogo: {
    width: 34,
    height: 36,
    verticalAlign: 'bottom',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      width: 40,
      height: 41,
      verticalAlign: 'bottom',
    },
  },
  divider: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 330,
    width: '100%',
    boxSizing: 'border-box',
    backgroundColor: COLORS.lightBlack,
    color: COLORS.white,
    padding: 20,
    '& p': {
      marginBottom: 5,
      width: '335px',
    },
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 30,
    '& p': {
      margin: 0,
    },
  },
  customColor: {
    color: COLORS.darkGrey,
  },
  uploadIcon: {
    width: 36,
    height: 36,
    verticalAlign: 'middle',
  },
  plusIcon: {
    width: 21,
    height: 21,
  },
  homeScreenButton: {
    width: 325,
    height: 50,
    marginBottom: 5,
    '& img': {
      width: '100%',
    },
  },
  flexOrder: {
    display: 'flex',
    flexDirection: 'column',
  },
  brandsList: {
    paddingTop: 100,
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {},
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      paddingTop: 120,
    },
  },
  mobileBackground: {
    width: '100%',
    margin: '0 auto',
    maxWidth: WIDTH_DIMENSIONS.max,
    padding: '40px 0 154px',
    backgroundColor: COLORS.whiteStain,
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      padding: '40px 5% 154px',
    },
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      backgroundColor: COLORS.white,
      padding: '0px 0px',
      width: '95%',
    },
  },
  mobileTitle: {
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      textAlign: 'center',
    },
  },
  howItWorksMobileSection: {
    paddingTop: 100,
    position: 'relative',
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      paddingTop: 0,
    },
  },
  howItWorksOnMobileTitle: {
    position: 'absolute',
    top: -2,
    marginBottom: 0,
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      position: 'static',
    },
  },
  middleSectionContent: {
    lineHeight: '23px',
  },
  middleSection: {
    display: 'flex',
    width: '95%',
  },
  middleSectionImg: {
    width: 940,
    height: 454,
    objectFit: 'cover',
  },
  middleSectionTitle: {
    marginBottom: 20,
  },
  sectionContainer: {
    width: '100%',
    overflow: 'hidden',
    paddingTop: 100,
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      width: '90%',
      margin: '0 auto',
      paddingTop: 120,
    },
  },
  headingText: {
    marginBottom: 40,
    marginTop: 10,
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      marginBottom: 60,
      marginTop: 30,
    },
  },
  floatRightButton: {
    [`@media (max-width: ${BREAKPOINTS.lg - 1}px)`]: {
      float: 'right',
      marginBottom: 60,
    },
  },
  breadcrumb: {
    [`& li.MuiBreadcrumbs-li a`]: {
      fontSize: '14px !important',
      fontWeight: 300,
      color: COLORS.black,
      letterSpacing: '0.38px',
      lineHeight: '15px',
      cursor: 'pointer',
      fontFamily: 'Graphik Light',
    },
    [`& li.MuiBreadcrumbs-separator`]: {
      fontSize: '14px !important',
      fontWeight: 300,
      letterSpacing: '0.38px',
      lineHeight: '15px',
      fontFamily: 'Graphik Light',
      color: COLORS.black,
      marginTop: 5,
    },
    [`& li.MuiBreadcrumbs-li p`]: {
      height: 27.43,
      fontSize: '14px !important',
      fontWeight: 300,
      letterSpacing: '0.38px',
      lineHeight: '31px',
      fontFamily: 'Graphik Light',
      color: COLORS.black,
    },
  },
  addChirpyestButton: {
    position: 'absolute',
    bottom: -95,
    right: '5%',
    width: '50%',
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      position: 'static',
      display: 'block',
    },
  },
  subtitleSentence: {
    fontSize: '24px',
    lineHeight: '34px',
    letterSpacing: 'normal',
  },
  landingSections: {
    height: 400,
    padding: '0 19%',
    display: 'flex',
    flexDirection: 'column',
    [`&:nth-of-type(odd)`]: {
      background: COLORS.whiteStain,
    },
    [`&:nth-child(1)`]: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    [`&:nth-child(2)`]: {
      justifyContent: 'center',
      alignItems: 'start',
    },
    [`&:nth-child(3)`]: {
      justifyContent: 'center',
      alignItems: 'end',
      [`& > p`]: {
        width: '65%',
        textAlign: 'right',
        [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
          width: '75%',
        },
        [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
          width: '100%',
          textAlign: 'center',
        },
      },
    },
    [`&:nth-child(4)`]: {
      justifyContent: 'center',
      alignItems: 'start',
    },
    [`&:nth-child(5)`]: {
      justifyContent: 'center',
      alignItems: 'end',
    },
    [`&:nth-child(7)`]: {
      background: COLORS.whiteStain,
      justifyContent: 'center',
      alignItems: 'center',
      [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
        height: 400,
      },
    },
    [`& .carousel-root`]: {
      margin: 0,
    },
    [`& .carousel .control-dots`]: {
      bottom: -55,
      [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
        bottom: -35,
      },
      [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
        bottom: -26,
      },
    },
    [`& .carousel .slide img`]: {
      width: 100,
      height: 100,
      marginBottom: 15,
    },
    [`& .carousel.carousel-slider`]: {
      overflow: 'inherit',
    },

    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      padding: '0 11%',
      height: 350,
    },
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      padding: '0 5%',
      textAlign: 'center',
      justifyContent: 'center !important',
      alignItems: 'center !important',
    },
  },
  buttonStyles: {
    width: '25%',
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      width: '39%',
    },
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      width: '100%',
    },
  },
  buttonOutlineStyles: {
    width: '25%',
    backgroundColor: COLORS.white,
    margin: 0,
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      width: '39%',
    },
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      width: '100%',
    },
  },
  headingMedium: {
    fontFamily: FONTS.Schnyder.SchnyderMLight + ' !important',
    fontSize: '40px !important',
    lineHeight: '45px !important',
    letterSpacing: 'normal',
    marginBottom: '20px !important',
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      fontSize: '36px !important',
      lineHeight: '40px !important',
    },
  },
  quote: {
    fontFamily: FONTS.Schnyder.SchnyderMLightItalic + ' !important',
    fontSize: '40px !important',
    fontStyle: 'italic',
    lineHeight: '45px !important',
    letterSpacing: 'normal',
    marginBottom: '20px !important',
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      fontSize: '36px !important',
      lineHeight: '40px !important',
    },
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      overflowWrap: 'anywhere',
    },
  },
  headingLarge: {
    fontFamily: FONTS.Schnyder.SchnyderMLight + ' !important',
    fontSize: '60px !important',
    lineHeight: '68px !important',
    letterSpacing: 'normal',
    marginBottom: '20px !important',
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      fontSize: '45px !important',
      lineHeight: '50px !important',
    },
  },
  paragraphStyle: {
    fontSize: '24px !important',
    lineHeight: '34px !important',
    letterSpacing: 'normal',
    marginBottom: '25px !important',
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      fontSize: '18px !important',
      lineHeight: '28px !important',
    },
  },
  subtitleBrandsSentence: {
    fontSize: '24px',
    lineHeight: '34px',
    letterSpacing: 'normal',
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      textAlign: 'center',
      fontSize: '18px',
    },
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      width: '93%',
    },
  },
  brandsHeading: {
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      textAlign: 'center',
      fontSize: '36px',
      lineHeight: '40px',
    },
  },
  mobileBackgroundStyle: {
    width: '100%',
    margin: '0 auto',
    maxWidth: WIDTH_DIMENSIONS.max,
    padding: '40px 0 154px',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      padding: '40px 1% 154px',
    },
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      backgroundColor: COLORS.white,
      padding: '0px 0px',
      width: '95%',
    },
  },
  brandsListStyle: {
    paddingTop: 100,
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {},
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      paddingTop: 45,
    },
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      paddingTop: 45,
    },
  },
  addChirpyestButtonStyle: {
    position: 'absolute',
    bottom: -95,
    right: '5%',
    width: '50%',
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      position: 'static',
      display: 'block',
    },
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      right: 0,
      transform: 'translate(-52%, -50%)',
      left: '50%',
      width: '30.6%',
      bottom: -107,
    },
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      right: 0,
      transform: 'translate(-50%, -50%)',
      left: '50%',
      width: '90%',
      bottom: -107,
    },
  },
  waitingFor: {
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      marginBottom: 45,
    },
  },
  quoteImage: {
    width: 100,
    height: 100,
  },
  link: {
    textDecoration: 'none',
    color: COLORS.black,
  },
  whiteLink: {
    textDecoration: 'none',
    color: COLORS.white,
  },
}))
